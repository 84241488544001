<template>
  <div class="card h-100">
    <div class="card-body">
      <div class="d-flex justify-content-between align-items-center mb-3">
        <span class="badge" :class="item.badge_css">{{ item.category_title }}</span>
      </div>
      <h3 class="mb-4">{{ item.title }}</h3>
      <div class="media align-items-center mt-auto">
        <div class="avatar avatar-md avatar-circle mr-3">
          <img class="avatar-img" style="border: 2px solid #f4f5fa" :src="require('@/assets/img/events/apidays-live-hongkong/' + item.speaker_img)" :alt="item.speaker_name">
        </div>
        <div class="media-body">
          <span class="text-dark">
            <div class="h5 mb-0">{{ item.speaker_name }}</div>
          </span>
          <small class="d-block">{{ item.speaker_position }}</small>
        </div>
      </div>
    </div>
    <div class="card-footer border-0 pt-0">
      <div class="row gutters-sm">
        <div class="col-12 col-sm-6 col-md-12 col-xl-6">
          <a v-if="item.video_url" data-gtm-att="ga-event-apidays-recap-replay-video-button" class="btn btn-sm btn-block btn-primary" :href="item.video_url" target="_blank">Replay Video</a>
        </div>
        <div class="col-12 col-sm-6 col-md-12 col-xl-6">
          <a v-if="item.slide_url" data-gtm-att="ga-event-apidays-recap-view-slide-button" class="btn btn-sm btn-block btn-outline-primary mt-3 mt-sm-0 mt-md-3 mt-xl-0" :href="item.slide_url" target="_blank">View Slide</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'bootstrap'

export default {
  name: 'ApidaysRecapCard',
  props: {
    item: {
      type: Object,
      default: () => {
        return {
          url: '',
          image_url: '',
          category: '',
          title: '',
          description: '',
          date: '',
          time: ''
        }
      }
    }
  }
}
</script>
