<template>
  <main id="content" role="main">

    <!-- ========== Hero Section ========== -->
    <div class="position-relative z-index-2 bg-img-hero overflow-hidden" :style="{'background-image': 'url(' + require('@/assets/svg/components/hk-bg-light-2.jpg') + ')'}">
      <!-- Breadcrumb -->
      <div class="container space-top-2 mt-md-6 pl-0">
        <div class="row justify-content-sm-between mb-5">
          <div class="col-md">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb mb-md-3 mb-0">
                <li class="breadcrumb-item">
                  <!-- <a href="/events"> -->
                  <router-link :to="{ path: '/events' }">
                    Events
                  </router-link>
                  <!-- </a> -->
                </li>
                <li class="breadcrumb-item">
                  <!-- <a href="/events/apidays-live-hongkong-tc"> -->
                  <router-link :to="{ path: '/events/apidays-live-hongkong-2022' }">
                    Apidays Hong Kong 2022
                  </router-link>
                  <!-- </a> -->
                </li>
                <li class="breadcrumb-item active" aria-current="page">Apidays 2022 Recap</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <!-- Title -->
      <div class="container space-bottom-2 position-relative">
        <div class="w-lg-60 text-center mx-lg-auto">
          <div class="mb-5">
            <img class="img-fluid mb-5 max-w-19rem" src="@/assets/img/events/apidays-live-hongkong/2022/images/apidays-hongkong-2022-logo.png">
            <h1 class="mb-3">Apidays 2022 Recap</h1>
            <p class="lead">Watch playback videos and discover other resources of the event.</p>
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End Hero Section ========== -->

    <div style="background-color: rgb(249 254 255)">
      <div class="container space-2">
        <input v-for="item in category_list" :id="item.category" :key="item.category" v-model="selected_category" type="radio" name="categories" :value="item.category">
        <ol class="filters-btn" style="margin-left: 1rem;">
          <li v-for="item in category_list" :key="item.category">
            <label class="btn btn-sm btn-soft-secondary btn-pill py-2 px-3 m-1 mb-2" :class="{'selectedCategory': selected_category === item.category}" :for="item.category">{{ item.category_title }}</label>
          </li>
        </ol>
        <ol class="filters-container" style="padding: 0 1.5rem 1.5rem 1.5rem">
          <div class="row">
            <li v-for="item in filtered_category_list" :key="item.url" class="col-md-6 col-lg-4 mb-5 filters-item" :data-category="item.category">
              <ApidaysRecapCard :item="item" />
            </li>
          </div>
        </ol>
      </div>
    </div>

  </main>
</template>

<script>
import ApidaysRecapCard from '@/components/apidays-recap-card'
import { mapGetters } from 'vuex'
export default {
  name: 'Apidays2022Recap',
  components: {
    ApidaysRecapCard
  },
  data() {
    return {
      category_list: [],
      filtered_category_list: [],
      apidays_2022_recap_list: [],
      selected_category: 'All'
    }
  },
  computed: {
    ...mapGetters([
      'apidays2022recap'
    ])
  },
  created() {
    this.apidays_2022_recap_list = this.$store.getters.apidays2022recap.apidays_2022_recap_list
    this.filtered_category_list = this.apidays_2022_recap_list.filter(item => {
      return item.hidden !== true
    })
    this.getCategoryList(this.filtered_category_list)
  },
  methods: {
    getCategoryList(list) {
      function compare(a, b) {
        // Use toUpperCase() to ignore character casing
        const titleA = a.category_title.toUpperCase()
        const titleB = b.category_title.toUpperCase()

        let comparison = 0
        if (titleA > titleB) {
          comparison = 1
        } else if (titleA < titleB) {
          comparison = -1
        }
        return comparison
      }
      let array = []
      const temp = []
      for (let i = 0; i < list.length; i++) {
        const object = {
          category_title: '',
          category: ''
        }
        object.category_title = list[i].category_title
        object.category = list[i].category
        array = array.concat(object)
      }
      const map = new Map()
      for (const item of array) {
        if (!map.has(item.category_title)) {
          map.set(item.category_title, true)
          temp.push({
            category_title: item.category_title,
            category: item.category
          })
        }
      }
      temp.sort(compare)
      this.category_list = [{ category_title: 'All', category: 'All' }]
      this.category_list = this.category_list.concat(temp)
    }
  },
  metaInfo() {
    return {
      title: 'beNovelty',
      titleTemplate: 'Apidays 2022 Recap | Apidays Hong Kong 2022 | %s',
      link: [
        { rel: 'canonical', href: 'https://www.benovelty.com/events/apidays-live-hongkong-2022/recap' }
      ],
      meta: [
        { name: 'description', content: 'Discover beNovelty\'s state-of-the-art API-First and intelligent Agentic AI Solutions, designed to elevate your enterprise\'s digital transformation.' },
        { property: 'og:site_name', content: 'beNovelty' },
        { property: 'og:title', content: 'Apidays 2022 Recap | Apidays Hong Kong 2022 | beNovelty' },
        { property: 'og:description', content: 'Watch replay videos and discover other resources of Apidays Hong Kong 2022.' },
        { property: 'og:image', content: 'https://www.benovelty.com/assets/img/thumbnails/apidays-live-hongkong-2022.jpg' },
        { property: 'og:url', content: 'https://www.benovelty.com/events/apidays-live-hongkong-2022/recap' },
        { name: 'twitter:card', content: 'summary_large_image' }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import url("../../../../assets/vendor/slick-carousel/slick/slick.css");
</style>
